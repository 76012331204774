<template>
  <ion-card :class="inList ? 'ion-no-margin in-list' : 'ion-no-margin'">
    <ion-card-header
      class="ion-no-padding"
      @click="() => $router.push(`/event/${id}`)"
    >
      <ion-img
        class="head-img ion-no-padding"
        :src="
          image != undefined && image.length > 0
            ? image[0].url
            : require('@//assets/img/placeholder_event.png')
        "
      ></ion-img>
      <div class="ion-padding">
        <ion-card-subtitle
          >{{ formattedStartDate }}
          {{
            showDetails && formattedEndDate.length > 0
              ? `- ${formattedEndDate}`
              : ""
          }}</ion-card-subtitle
        >
        <ion-card-title>{{ name }}</ion-card-title>
        <ion-label>
          {{ interested }} má zájem &middot; {{ participate }} se zůčastní
        </ion-label>
      </div>
    </ion-card-header>

    <ion-card-content>
      <ion-label v-if="showDetails">
        <p class="description">{{ description }}</p>
      </ion-label>
      <ion-row>
        <template v-if="!isParticipate">
          <ion-col size="6">
            <ion-button expand="block" color="light" @click="setInterested()">
              <b>{{ isInterested ? "Už mě to nezajímá" : "Zajímá mě to" }}</b>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button
              expand="block"
              color="primary"
              @click="setParticipated()"
            >
              <b>Zůčastním se</b>
            </ion-button>
          </ion-col>
        </template>
        <template v-else>
          <ion-col size="12">
            <ion-button color="light" expand="block" @click="setParticipated()">
              <b>Nezůčastním se</b>
            </ion-button>
          </ion-col>
        </template>
      </ion-row>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EventCard",
  props: {
    id: Number,
    name: String,
    startDate: Number,
    endDate: Number,
    participate: Number,
    interested: Number,
    description: String,
    showDetails: Boolean,
    isInterested: Number,
    isParticipate: Number,
    image: Array,
    inList: Boolean,
  },
  data() {
    return {
      isLikedByUser: false,
    };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLabel,
    IonCardContent,
    IonRow,
    IonImg,
    IonCol,
    IonButton,
  },
  setup() {
    return {};
  },
  computed: {
    formattedStartDate() {
      // console.log("moment", this.time);
      // moment().locale("cs");
      return moment
        .unix(this.startDate)
        .locale("cs")
        .format("MMMM Do YYYY, h:mm");
    },
    formattedEndDate() {
      // console.log("moment", this.time);
      // moment().locale("cs");
      if (this.endDate) {
        return moment
          .unix(this.endDate)
          .locale("cs")
          .format("MMMM Do YYYY, h:mm");
      } else {
        return "";
      }
    },
    ...mapGetters({
      getActiveId: "social/getActiveId",
    }),
  },
  methods: {
    ...mapActions({
      setInterest: "events/setInterest",
      setParticipate: "events/setParticipate",
    }),
    setInterested() {
      this.setInterest({ eventId: this.id, networkId: this.getActiveId });
    },
    setParticipated() {
      this.setParticipate({ eventId: this.id, networkId: this.getActiveId });
    },
  },
};
</script>

<style scoped>
ion-card-subtitle {
  color: var(--ion-color-primary);
}
ion-card-title {
  margin: 8px 0px;
}
.description {
  color: #000;
  margin: 4px 0px 20px 0px !important;
}
ion-img::part(image) {
  max-height: 25vh;
  object-fit: cover;
}
.in-list ion-img::part(image) {
  height: 215px;
}
</style>
